import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { findDOMNode, createPortal } from "react-dom";
import FullCalendar from "@fullcalendar/react";
import moment from "moment-timezone";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
    VIEWS,
    CALENDAR_LICENSE_KEY,
    EVENT_VIEW,
    VIEW_DURATION_DAYS,
} from "../constants/index";
import CalendarEvent from "./offer-calendar-events";
import "./offer-calendar.less";
import { DateSelect } from "../../../form/rsuite-form";
import { OrderContext } from "../helper/order-context";
import { MINUTE, getDateStartTime } from "../helper/utility";
import { DateControl } from "./offer-calendar-date-control";
import { DateHeader } from "./offer-calendar-date-header";
import { createDayStartEvent } from "../helper/events-utilities";
const SLOT_LABEL_FORMAT = {
    hour: "numeric",
    minute: "2-digit",
    meridiem: false,
    hour12: false,
};

const PLUGINS = [
    dayGridPlugin,
    timeGridPlugin,
    resourceTimeGridPlugin,
    interactionPlugin,
    momentPlugin,
];

const TOOLBAR = {
    left: "",
    center: "",
    right: "",
};

const CalendarControlPortal = ({ calendarView, children }) => {
    const containerRef = useRef();
    useEffect(() => {
        containerRef.current = calendarView?.el?.querySelector(
            ".fc-timegrid-axis"
        );
    }, [calendarView]);

    return containerRef.current
        ? createPortal(children, containerRef.current)
        : null;
};

export const OfferCalendar = ({
    events = [],
    eventClick,
    eventDrop,
    eventResize,
    selectedDate = moment(),
    initialDate = moment(), // default to current date
    activeView = VIEWS.DAY,
    onDateChange,
    onDeleteEvent,
    eventStartEditable,
    eventDurationEditable,
    changeCalendarView,
}) => {
    // const CALENDAR_LICENSE_KEY = "0599544215-fcs-1629197359";
    const {
        updateDateStartTime,
        dayStartTimes,
        addArticleOnDateClick,
        order,
        offerStartTime,
        offerEndTime,
    } = useContext(OrderContext);
    const calendarRef = useRef();
    useEffect(() => {
        changeView(activeView);
    }, [activeView]);

    const changeView = (view) => {
        const api = calendarRef.current?.getApi();
        api?.changeView(view === VIEWS.LONG_WEEK ? VIEWS.WEEK : view);
    };
    const onHeaderClick = useCallback((date) => onDateChange?.(date), [
        onDateChange,
    ]);
    const renderHeader = useCallback(
        ({ date }) => {
            const _date = getDateStartTime(date, dayStartTimes);
            return (
                <>
                    <DateHeader
                        date={date}
                        onHeaderClick={onHeaderClick}
                        dateStartTime={_date.toDate()}
                    />
                    <div className="date-start-picker-container">
                        <div className="date-start-picker-title">
                            <p>Abfahrt um</p>
                        </div>
                        <DateSelect
                            className="date-start-picker"
                            format="HH:mm"
                            cleanable={false}
                            block
                            ranges={[]}
                            value={_date.toDate()}
                            onChange={(d) =>
                                updateDateStartTime?.(
                                    _date.format("YYYY-MM-DD"),
                                    moment(d).format("HH:mm")
                                )
                            }
                            locale={{
                                hours: "Std.",
                                minutes: "Minuten",
                                ok: "OK",
                            }}
                        />
                    </div>
                </>
            );
        },
        [onHeaderClick, dayStartTimes]
    );

    const renderEventContent = useCallback(
        ({ event, timeText }) => (
            <CalendarEvent
                onDeleteEvent={onDeleteEvent}
                event={event}
                timeText={timeText}
            />
        ),
        [onDeleteEvent]
    );

    const [calView, setCalView] = useState(null);

    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        const calendarEl = findDOMNode(calendarRef.current);
        const dayColumnsSelector = ".fc-day[data-date]";
        const dayColumns = calendarEl?.querySelectorAll(dayColumnsSelector);
        dayColumns?.forEach((item) => item.classList.remove("fc-selected-day"));
        const api = calendarRef.current.getApi();
        if ([VIEWS.WEEK, VIEWS.LONG_WEEK].includes(activeView)) {
            const startDate = moment(api.getDate()).startOf("day");
            const endDate = moment(startDate).add(
                VIEW_DURATION_DAYS[activeView],
                "days"
            );
            // check if selected date is visible in view
            const isDateInView = selectedDate.isBetween(
                startDate,
                endDate,
                "day",
                "[)"
            );
            if (!isDateInView) {
                // if not go to date
                api.gotoDate(selectedDate.format("YYYY-MM-DD"));
            }
            const markSelectedDate = () => {
                const daySelector = `.fc-day[data-date="${selectedDate.format(
                    "YYYY-MM-DD"
                )}"]`;
                const dayCells = calendarEl?.querySelectorAll(daySelector);
                dayCells.forEach((item) =>
                    item.classList.add("fc-selected-day")
                );
            };
            isDateInView
                ? markSelectedDate()
                : setTimeout(markSelectedDate, 300);
        }
        if (activeView === VIEWS.DAY) {
            api.gotoDate(selectedDate.format("YYYY-MM-DD"));
        }
    }, [selectedDate, activeView, calendarRef.current]);
    const openCreateArticleModal = useCallback(({ date, start, end }) => {
        addArticleOnDateClick(
            {
                type: "workflow",
                workflowId: "order/order-item-add",
                formId: "order/order-item-add",
                label: "Artikel einfügen",
            },
            {
                id: order.id,
                modelId: order.modelId,
                data: {
                    formData: {
                        provider: "order/no-provider-item",
                        order: { id: order.id, modelId: order.modelId },
                        ...(!!date
                            ? {
                                  fromDate: date,
                                  toDate: moment(date)
                                      .tz("Europe/Berlin")
                                      .toDate(),
                              }
                            : { fromDate: start, toDate: end }),
                    },
                },
            }
        );
    });

    const onEventClick = useCallback(
        (event) => {
            const isDeleteEvent =
                event.jsEvent.target.className.includes("delete-button") ||
                event.jsEvent.target.parentElement.className.includes(
                    "delete-button"
                );
            const ignoredCalendarEvents = [
                EVENT_VIEW.TRAVEL,
                EVENT_VIEW.HOME,
                EVENT_VIEW.DAY_START,
                EVENT_VIEW.ACCOMMODATION_SHADOW,
            ];
            if (
                ignoredCalendarEvents.includes(
                    event.event.extendedProps.eventView
                ) ||
                isDeleteEvent
            ) {
                return;
            }
            !isDeleteEvent && eventClick(event);
        },
        [eventClick]
    );
    const _events = useMemo(() => {
        const api = calendarRef.current?.getApi();
        const startDate = moment(api?.getDate()).startOf("day");
        const visibleDays = [
            startDate,
            ...Array(VIEW_DURATION_DAYS[activeView] - 1)
                .fill(0)
                .map((_, i) => moment(startDate).add(i + 1, "day")),
        ]
            .map((day) => getDateStartTime(day, dayStartTimes))
            .map(createDayStartEvent);

        return [...events, ...visibleDays];
    }, [events, dayStartTimes, activeView, calendarRef.current, initialDate]);
    return (
        <>
            <FullCalendar
                stickyHeaderDates
                schedulerLicenseKey={CALENDAR_LICENSE_KEY}
                plugins={PLUGINS}
                ref={calendarRef}
                initialDate={initialDate.format("YYYY-MM-DD")}
                headerToolbar={TOOLBAR}
                views={{
                    timeGrid: {
                        type: VIEWS.WEEK,
                        duration: {
                            days:
                                VIEW_DURATION_DAYS[
                                    activeView === VIEWS.LONG_WEEK
                                        ? VIEWS.LONG_WEEK
                                        : VIEWS.WEEK
                                ],
                        },
                        dateIncrement: { days: 1 },
                    },
                    timeGridDay: {
                        type: VIEWS.DAY[VIEWS.DAY],
                        duration: { days: 1 },
                    },
                }}
                titleFormat={"DD. MMMM YYYY"}
                dayHeaderContent={renderHeader}
                allDayText={"Ganzer Tag"}
                slotLabelFormat={SLOT_LABEL_FORMAT}
                locale={"en-GB"}
                editable
                eventStartEditable={eventStartEditable}
                eventDurationEditable={eventDurationEditable}
                eventDrop={eventDrop}
                eventResize={eventResize}
                contentHeight={"auto"}
                slotDuration={"00:30:00"}
                slotLabelInterval={"00:30:00"}
                slotMinTime={"00:00:00"}
                events={_events}
                eventContent={renderEventContent}
                eventClick={onEventClick}
                droppable
                viewDidMount={setCalView}
                dateClick={openCreateArticleModal}
                selectable
                selectMinDistance={10}
                select={openCreateArticleModal}
                validRange={{
                    start: offerStartTime?.format(),
                    end: offerEndTime?.clone().add(1, "day").format(),
                }}
            />
            <CalendarControlPortal calendarView={calView}>
                <DateControl
                    onDateChange={onDateChange}
                    changeCalendarView={changeCalendarView}
                    calendarView={calView}
                    activeView={activeView}
                />
            </CalendarControlPortal>
        </>
    );
};
